:root {
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;

    --font-size-10: 1rem;
    --font-size-12: 1.2rem;
    --font-size-13: 1.3rem;
    --font-size-14: 1.4rem;
    --font-size-16: 1.6rem;
    --font-size-18: 1.8rem;
    --font-size-20: 2rem;
    --font-size-24: 2.4rem;
    --font-size-32: 3.2rem;
    --font-size-40: 4rem;
    --font-size-48: 4.8rem;
    --font-size-56: 5.6rem;
    --font-size-64: 6.4rem;
    --font-size-72: 7.2rem;
    --font-size-80: 8rem;

    --line-height-16: 1.6rem;
    --line-height-18: 1.8rem;
    --line-height-20: 2rem;
    --line-height-24: 2.4rem;
    --line-height-26: 2.6rem;
    --line-height-28: 2.8rem;
    --line-height-32: 3.2rem;
    --line-height-40: 4rem;
    --line-height-48: 4.8rem;
    --line-height-56: 5.6rem;
    --line-height-64: 6.4rem;
    --line-height-72: 7.2rem;
    --line-height-88: 8.8rem;
    --line-height-96: 9.6rem;
}

.icon,
.icon-small {
    height: 2.4rem;
    width: 2.4rem;
}

.icon.icon-xsmall {
    height: 2rem;
    width: 2rem;
}

.icon.icon-medium {
    height: 3.6rem;
    width: 3.6rem;
}

.icon.icon-large {
    height: 4.8rem;
    width: 4.8rem;
}

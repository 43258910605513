@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%; /* it sets base font size to 10px */
}

html,
body {
    font-family: "Inter", sans-serif;
}

a {
    text-decoration: none;
}
